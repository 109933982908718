import logo from './logo.svg';
import './App.css';
import backgroundSpheary from './images/spheary.jpg'
function App() {
  return (
     
<div className="h-screen bg-black bg-[url('./images/spheary.jpg')] bg-no-repeat bg-center bg-cover">

<div className="flex flex-col justify-between items-center h-full mx-auto py-4 max-w-6xl px-4">

    <div className="flex items-start justify-start w-full ">
        <h1 className="text-4xl tracking-wider text-white">SPHEARY</h1>
    </div>

    <div className="flex flex-col space-y-10 items-center justify-center">

        <div>
            <h1 className="text-5xl md:text-9xl font-extrabold py-2">
                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-green-300 to-blue-200">Coming</span>
                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-blue-200 to-red-500">Soon</span>
            </h1>
        </div>

        <div className="max-w-xl flex justify-center w-full">
            <p className="text-white font-semibold text-center bg-black/40">
            With Spheary, virtual and physical sounds can be mixed in roomscale and outdoor installations. #AugmentedAudio #MixedReality.
            </p>
        </div>

        <div className="max-w-xl flex justify-center w-full">
            <p className="text-white font-semibold text-center bg-black/40">
            Check us out. Spheary is a Luch.us and Rabot Rummel co-production.
            <br/>
            <br/>
            <b>
            <a href='https://www.luchs.us'>Link to: Luchs.us</a>
            </b>
            <br/><br/>
            <b>
            <a href='https://www.rabotrummel.de'>Link to: Rabot Rummel</a>
            </b>
            </p>
        </div>
{/**
        <div className="max-w-xl flex justify-center w-full">

            <div className="flex justify-between items-center space-x-2 bg-gray-900 py-2 px-4 w-full rounded">
                <input
                        className="focus:text-gray-300 focus:outline-none focus:ring-0 text-gray-600 bg-gray-900 w-1/2"
                        placeholder="Please enter your email address"
                />
                <button className="rounded bg-white hover:bg-gray-300 text-gray-900 py-2 px-8"
                        type="submit">
                Notify Me
                </button>
            </div>

        </div>

        <div className="flex items-center justify-center">
            <p className="text-gray-300 text-sm  bg-black/70 backdrop-blur-xl">
                Notify me when SPHEARY is ready
            </p>
        </div>
        */}
    </div>
     

    <div className="text-gray-500 text-sm flex items-center justify-between w-full">
        <div>
            <a href="#" className="">Privacy Policy</a>
        </div>

        <div>
          {/** 
            <ul className="flex flex-row space-x-4 items-center justify-center">
                <li><a href="#" className="">Facebook</a></li>
                <li><a href="#" className="">Instagram</a></li>
                <li><a href="#" className="">Twitter</a></li>
            </ul>
            */}
        </div>
    </div>
</div>

</div>


   
  );
}

export default App;
